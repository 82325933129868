import { Link as BaseLink } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const linkStyle = css`
  text-decoration: none;
  color: #222;
  border-bottom: 1px solid transparent;

  &.active,
  :hover,
  :focus {
    border-bottom: 1px solid;
  }
`

export const paragraphLinkStyle = css`
  ${linkStyle};
  color: #5d93ff;
`

export const Link = styled(BaseLink)`
  ${linkStyle};
`

export const ReadLink = styled(Link)`
  ${paragraphLinkStyle};
  text-transform: uppercase;
`

export const CategoryLink = styled(Link)`
  && {
    color: #f7a046;
    text-transform: uppercase;
    :hover,
    :focus {
      color: #5d93ff;
      border-bottom-color: #5d93ff;
    }
  }
`
