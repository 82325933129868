import React, { useState } from 'react'
import { Link as BaseLink, useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRss } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import { title, description, email } from '../metadata'
import { Link } from './link'
import { Hamburger } from './hamburger'

export function Sidebar() {
  const [menuOpen, toggleMenuOpen] = useState(false)
  const { profileImage } = useStaticQuery(logoImageQuery)
  return (
    <>
      <div css={[headerCss, menuOpen && headerActiveCss]}>
        <BaseLink to="/" title={title}>
          <div css={profileCss}>
            <Img alt={title} fluid={profileImage.childImageSharp.fluid} />
          </div>
        </BaseLink>
        <h1 css={titleCss}>
          <Link to="/">{title}</Link>
        </h1>
        <Hamburger
          className="hamburger"
          active={menuOpen}
          onClick={() => toggleMenuOpen(!menuOpen)}
        />
      </div>
      <div css={[footerCss, menuOpen && footerActiveCss]}>
        <p css={descriptionCss}>{description}</p>
        <nav css={menuCss}>
          <ul>
            <li>
              <Link to="/" activeClassName="active">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/spoluprace/" activeClassName="active">
                Spolupráce
              </Link>
            </li>
            <li>
              <Link to="/kontakt/" activeClassName="active">
                Kontakt
              </Link>
            </li>
          </ul>
        </nav>
        <nav css={linksCss}>
          <ul>
            <li>
              <a href={`mailto:${email}`} title="E-mail">
                <FontAwesomeIcon size="sm" icon={faEnvelope} />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/nikolakvapilova.cz/"
                rel="noopener noreferrer"
                title="Facebook"
                target="_blank">
                <FontAwesomeIcon size="sm" icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/nikola-kvapilova/"
                rel="noopener noreferrer"
                title="LinkedIn"
                target="_blank">
                <FontAwesomeIcon size="sm" icon={faLinkedinIn} />
              </a>
            </li>
            <li>
              <a
                href="/rss.xml"
                rel="noopener noreferrer"
                title="RSS"
                target="_blank">
                <FontAwesomeIcon size="sm" icon={faRss} />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

const logoImageQuery = graphql`
  query {
    profileImage: file(relativePath: { eq: "nikola-svagerova.jpeg" }) {
      childImageSharp {
        fluid(
          maxHeight: 100
          maxWidth: 100
          quality: 100
          srcSetBreakpoints: [900]
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const headerCss = css`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  transition: box-shadow 0.55s ease-in-out;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  background-color: inherit;
  @media screen and (min-width: 768px) {
    padding: 1.5rem 3.5rem;
  }
  @media screen and (min-width: 900px) {
    position: static;
    display: block;
    box-shadow: none;
    transition: none;
    flex-direction: column;
    padding: 3.5rem 3.5rem 0 3.5rem;
    .hamburger {
      display: none;
    }
  }
`

const headerActiveCss = css`
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0);
  width: 100%;
`

const footerCss = css`
  position: absolute;
  z-index: 1;
  padding: 0 1.5rem 1rem 1.5rem;
  width: 100%;
  transition: transform 0.55s ease-in-out;
  transform: translate(0, -80vh);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  background-color: inherit;
  > * {
    transition: opacity 0.55s ease-in;
    opacity: 0;
  }
  @media screen and (min-width: 768px) {
    padding: 0 3.5rem 1.5rem 3.5rem;
  }
  @media screen and (min-width: 900px) {
    position: static;
    transition: none;
    transform: none;
    box-shadow: none;
    > * {
      transition: none;
      opacity: 1;
    }
  }
`

const footerActiveCss = css`
  transform: translate(0, 0);
  visibility: visible;
  > * {
    opacity: 1;
  }
`

const profileCss = css`
  position: relative;
  height: 50px;
  width: 50px;
  background: #eee;
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
  &::after {
    box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 50%;
  }
  @media screen and (min-width: 900px) {
    height: 100px;
    width: 100px;
  }
`

const titleCss = css`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.82813rem;
  margin: 0;
  @media screen and (min-width: 900px) {
    margin: 1.5rem 0 0 0;
  }
`

const descriptionCss = css`
  && {
    margin-top: 1.5rem;
    color: #888;
    font-weight: 300;
    text-align: left;
  }
`

const navCss = css`
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    padding: 0;
    margin: 0.625rem 0;
  }
`

const menuCss = css`
  ${navCss};
  margin-bottom: 1.25rem;
`

const linksCss = css`
  ${navCss};
  ul {
    display: flex;
  }
  li + li {
    padding-left: 0.5rem;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.1875rem;
    background: #ebebeb;
    color: #666;
    :hover {
      color: #333;
      cursor: pointer;
    }
    svg {
      height: 14px;
      width: 14px;
    }
  }
`
