import React from 'react'
import styled from '@emotion/styled'

import { paragraphLinkStyle } from './link'

import '../styles/global.css'

export function BaseLayout({ sidebar, children }) {
  return (
    <Body>
      {sidebar && <Sidebar>{sidebar}</Sidebar>}
      <Content>{children}</Content>
    </Body>
  )
}

/* Styles */

// $layout - breakpoint - sm: 685px;
// $layout - breakpoint - md: 960px;
// $layout - breakpoint - lg: 1100px;

const Sidebar = styled.aside`
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  @media screen and (min-width: 900px) {
    height: 100%;
    width: 30%;
    ::after {
      content: '';
      display: block;
      position: absolute;
      top: 3rem;
      right: 0;
      background: #e6e6e6;
      background: linear-gradient(180deg, #e6e6e6 0, #e6e6e6 48%, #fff);
      width: 1px;
      height: 540px;
    }
  }
`

const Content = styled.main`
  flex: 1;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.25rem;
    padding-top: 1.375rem;
    a {
      color: inherit;
    }
  }
  article.no-offset h1 {
    margin-top: 0;
    padding-top: 0;
  }
  p,
  ul,
  ol {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.625rem;
  }
  strong {
    font-weight: 400;
    color: #000;
  }
  a {
    ${paragraphLinkStyle};
  }
  p {
    margin-bottom: 1.25rem;
    hyphens: auto;
  }
  hr {
    margin-bottom: 1.25rem;
    margin-left: 1.25rem;
    padding-bottom: 4rem;
    text-align: center;
    background: none;
    :before {
      letter-spacing: 1.5rem;
      font-family: serif;
      font-size: 2rem;
      content: '...';
      color: #555;
    }
  }
  blockquote {
    margin: 0;
    border-left: 3px solid #eee;
    padding-left: 1rem;
  }
  video {
    display: block;
    max-width: 100%;
  }
  .gatsby-image-wrapper {
    margin-bottom: 1.625rem;
  }
`

const Body = styled.div`
  margin: 0 auto;
  min-width: 300px;
  max-width: 1070px;
  ${Content} {
    padding: 1.5rem;
  }
  @media screen and (min-width: 768px) {
    ${Content} {
      padding: 3.5rem;
    }
  }
  @media screen and (min-width: 900px) {
    display: flex;
  }
`
