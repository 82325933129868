import React from 'react'
import { css } from '@emotion/react'

export function Hamburger({ active, ...rest }) {
  return (
    <div css={hamburgerCss} {...rest}>
      <div css={[hamburgerInnerCss, active && hamburgerInnerActiveCss]}></div>
    </div>
  )
}

const hamburgerCss = css`
  position: relative;
  width: 30px;
  height: 24px;
  cursor: pointer;
  overflow: visible;
`

const hamburgerInnerCss = css`
  margin-top: -2px;
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  &,
  &::before,
  &::after {
    width: 30px;
    height: 3px;
    background-color: #444;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    top: -10px;
    bottom: -10px;
  }
  &::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      opacity 0.1s linear;
  }
  &::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`

const hamburgerInnerActiveCss = css`
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  &::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
      transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
      opacity 0.1s 0.22s linear;
  }
`
