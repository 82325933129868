const mainUrl = process.env.URL || 'https://nikola.svager.cz'
const deployUrl = process.env.DEPLOY_PRIME_URL || mainUrl

const title = 'Nikola Švágerová'
const description = 'Vylepšuji texty všeho druhu a pro radost píšu články o všem, co se může hodit.' // prettier-ignore
const email = 'nikola@svager.cz'
const siteUrl = process.env.CONTEXT === 'production' ? mainUrl : deployUrl

exports.title = title
exports.description = description
exports.email = email
exports.siteUrl = siteUrl
module.exports = { title, description, email, siteUrl }
